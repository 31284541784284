import React from 'react';
import HeaderImg from '../../assets/images/header-tmp.jpg';
import style from './header.module.scss'

class Header extends React.Component {

  render() {

    return (
      <div 
        className={style.header}
        style={{
          backgroundImage: 'url('+HeaderImg+')'
        }}>
      </div>
    );

  }

}

export default Header;
