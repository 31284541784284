import React from 'react';
import style from './datalist.module.scss';
import Post from '../../components/post/post';
import Venue from '../../components/venue/venue';
import Review from '../../components/review/review';
import Loader from '../../components/loader/loader';
import Select from 'react-select';
import classnames from 'classnames';
import moment from 'moment';

class Datalist extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      items: null,
      offset: 0,
      isBottom: false,
      showLoader: false,
      showSelectChangeLoader: false,
      totalItems: 0,
      selectedOption: null
    };

    this.fetchPosts = this.fetchPosts.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption) {
    this.setState({
      selectedOption, 
      showSelectChangeLoader: true,
      offset: 0
    }, ()=> {
      window.removeEventListener('scroll', this.handleScroll);
      this.fetchPosts(this.state.selectedOption.value);
    });
  }

  fetchPosts(option) {
    this.props.client.blogPosts(this.props.blog, {
      offset: this.state.offset, 
      tag: this.props.blog === 'paolospaccamonti-reviews' ? [!!this.state.selectedOption ? option : 'cln'] : []
    })
    .then(resp => {
      this.setState({
        showLoader: false,
        totalItems: resp.total_posts,
        showSelectChangeLoader: false,
      });
      if(resp.posts.length) {
        this.setState({
          items: this.state.offset === 0 ? resp.posts : 
          [
            ...this.state.items,
            ...resp.posts
          ]
        }, ()=> {
          if(this.state.offset === 0) {
            window.addEventListener('scroll', this.handleScroll, { passive: true });
          }
          this.setState({
            isBottom: false
          });
        });
      }else{
        return;
      }
    })
    .catch(function(err) {
      // oops
    });
  }

  handleScroll(event) {
    if ((this.state.items.length < this.state.totalItems) && !this.state.isBottom && (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
      this.setState({
        offset: this.state.offset + 20
      }, ()=> {
        this.setState({
          isBottom: true,
          showLoader: true
        }, ()=> {
          !!this.state.selectedOption ? this.fetchPosts(this.state.selectedOption.value) : this.fetchPosts();
        });
      });
    }
  }
  

  componentDidMount() {
    if(this.props.blog !== 'paolospaccamonti-reviews') {
      this.fetchPosts();
    }else{
      this.fetchPosts(this.state.selectedOption);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {

    const selctStyles = {
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          textAlign: 'left',
          fontSize: '0.9rem'
        };
      },
      input: styles => ({ 
        ...styles, 
        fontSize: '0.9rem',
        outline: 'none'
      }),
    };

    if (this.props.blog === 'paolospaccamonti') {
      return (
        <div className={style.datalist}>
          {!!this.state.items ? 
            <div className={classnames(style.wrapper, style.homepage)}>
              {this.state.items.map((p, index) => (
                <Post key={p.id} post={p} />
              ))}
              {this.state.showLoader && (
                <Loader />
              )}
            </div> :
            <div className={style.wrapper}>
              <Loader />
            </div>
          }
        </div>
      );
    } else if (this.props.blog === 'paolospaccamonti-tour') {

      if(!!this.state.items) {
        this.state.items.sort(function(a,b) {
          return new Date(b.slug) - new Date(a.slug);
        });
      }


      return (
        <div className={style.datalist}>
          {!!this.state.items ? 
            <div className={classnames(style.wrapper, style.tour)}>
              {this.state.items.map((t)=> (
                <Venue key={t.id} venue={t} />
              ))}
              {this.state.showLoader && (
                <Loader />
              )}
              </div> :
            <div className={style.wrapper}>
              <Loader />
            </div>
          }
        </div>
      );
    } else if (this.props.blog === 'paolospaccamonti-reviews') {
      return (
        <div className={style.datalist}>
          {!!this.state.items && !this.state.showSelectChangeLoader ? 
            <div className={classnames(style.wrapper, style.reviews)}>
              <div className={style.wrapSelect}>
                <div className={style.select}>
                  <Select
                    value={!!this.state.selectedOption ? this.state.selectedOption : this.props.options[0]}
                    onChange={this.handleChange}
                    options={this.props.options}
                    styles={selctStyles}
                  />
                </div>
              </div>
              <div className={style.cover}>
                <img src={!!this.state.selectedOption ? this.props.albumsData[this.state.selectedOption.value].cover : this.props.albumsData.cln.cover} alt=""/>
                <div className={style.description} dangerouslySetInnerHTML={{__html: !!this.state.selectedOption ? this.props.albumsData[this.state.selectedOption.value].infos : this.props.albumsData.cln.infos}}></div>
              </div>
              <div className={style.wrapReviews}>
              {this.state.items.map((r, index)=> (
                <Review
                  key={index}
                  review={r}
                />
              ))}
              {this.state.showLoader && (
                <Loader />
              )}
              </div>
            </div> :
            <div className={classnames(style.wrapper, style.reviews, style.loading)}>
              <Loader />
            </div>
          }
        </div>
      );
    }

  }

}

export default Datalist;
